import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/prosthetics_01.jpg'
import pic02 from '../assets/images/machine_learning_01.jpg'
import pic03 from '../assets/images/biomechanics_01.jpg'
import pic04 from '../assets/images/human_robot_interaction_01.jpg'
import pic05 from '../assets/images/other_research_01.jpg'
import pic06 from '../assets/images/teaching_01.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Jonathan Camargo"
                    meta={[
                        { name: 'description', content: 'Personal website' },
                        { name: 'keywords', content: 'Jonathan Camargo, Ph.D., Robotics, Portfolio, CV, Research, Teaching, Uniandes, GeorgiaTech' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Prosthetics</h3>
                                <p>Design, development, validation, intelligent control</p>
                            </header>
                            <Link to="/prosthetics" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Machine Learning</h3>
                                <p>Feature Engineering, Sensor Fusion, Locomotion Classification, Regression of Walking State</p>
                            </header>
                            <Link to="/machinelearning" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Biomechanics</h3>
                                <p>Wearable Sensing,  Experiment Design, Modeling and Simulation</p>
                            </header>
                            <Link to="/biomechanics" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Human Robot Interaction</h3>
                                <p>Intent recognition, Human augmentation, VR/AR, Emotional Robotics</p>
                            </header>
                            <Link to="/hri" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>Other Research</h3>
                                <p>System modeling, Multiphysics, and Scientific visualization</p>
                            </header>
                            <Link to="/other" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Teaching</h3>
                                <p>Docendo discimus</p>
                            </header>
                            <Link to="/teaching" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>About</h2>
                            </header>
                            <p>Welcome to my website!<br></br>
                            I am Jonathan Camargo, Ph.D. in Robotics from <a href="http://robotics.gatech.edu/education/phd">GeorgiaTech</a>. I am currently working as a Visiting Researcher in <a href="https://tech.fb.com/ar-vr/">Reality Labs</a> from Meta, where I am investigating and developing the future of virtual and augmented reality.
                            My interests are diverse but orbit around the field of Biomechatronics, wearable systems and human augmentation.<br></br>
                            I am an alumnus from <a href="https://www.uniandes.edu.co">Universidad de los Andes</a>. I double-majored
                            in Electronics and Mechanical Engineering and also did a Master in M.Eng. At Uniandes, I then worked
                            as an Instructor in Mechanical Engineering before embarking on the PhD journey in the <a href="http://www.epic.gatech.edu">Exoskeleton and Prosthetic Intelligent Control (EPIC)</a> Lab,                             
                            with the great support of a <a href="https://www.fulbright.edu.co/">Fulbright scholarship</a>

                            <br></br> My Ph.D. research focused on using the fusion of EMG signals and mechanical sensors for user intent recognition, working with both upper and lower limb power prosthetics.
                            My thesis improved the understanding of locomotion and human movement and developed better interfaces for joint control of prosthetic devices.                            
                            <br></br>  I'm passionate about creating low friction wearable systems that reliably incorporate the user in the loop for higher accuracy and proprioception. You can find a list of my publications <Link to="/publications">here</Link>.
                            <br></br>                            
                            

                            </p>
                            {/* <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex